<template>
  <WorkspaceLayout>
    <UDashboardPage>
      <UDashboardPanel grow>
        <Header title="Workspace Settings" />

        <UDashboardToolbar v-if="links[0].length > 1" class="py-0 px-1.5 overflow-x-auto">
          <UHorizontalNavigation :links="links" />
        </UDashboardToolbar>
        <NuxtPage />
      </UDashboardPanel>
    </UDashboardPage>
  </WorkspaceLayout>
</template>

<script setup lang="ts">
import WorkspaceLayout from '#core/layouts/workspace.vue'
import { useWorkspaceAbility } from '#auth/composables/ability'

useSeoMeta({
  title: 'Settings',
})

const { currentWorkspace } = useWorkspaceSharedState()
const { can } = useWorkspaceAbility()

const links = computed(() => {
  const handle = currentWorkspace.value?.handle
  return [[
    can('settings.general.edit_workspace_profile') && {
      label: 'General',
      icon: 'i-heroicons-cog-16-solid',
      to: `/w/${handle}/settings`,
      exact: true
    },
    can('settings.members.view_members') && {
      label: 'Team',
      icon: 'i-heroicons-users',
      to: `/w/${handle}/members`,
      exact: true
    },
    can('settings.settings_packs.manage_settings_packs') && {
      label: 'Settings Packs',
      icon: 'i-heroicons-rectangle-stack',
      to: `/w/${handle}/settings-packs`,
    }]]
})
</script>
